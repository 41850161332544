import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, AdminAccessComponent, TwoFactorComponent } from '@mt-ng2/login-module';
import { RequestSignatureComponent } from './common/request-signature/request-signature.component';
import { appPaths } from './admin-portal/default-routes/app-paths.library';
import { MtCanLoadAuthGuard, MtTwoFactorGuard } from '@mt-ng2/auth-module';
import { IsProviderLoadGuard } from 'guards/is-provider-load.guard';
import { IsAdminLoadGuard } from 'guards/is-admin-load.guard';
import { ResetPasswordComponent } from 'reset-password/reset-password.component';

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginComponent, title: 'Login' },
    { canActivate: [MtTwoFactorGuard], path: appPaths.verify2FA, component: TwoFactorComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent, title: 'Forgot Password' },
    { path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
    { path: appPaths.adminAccess, component: AdminAccessComponent, title: 'Admin Access' },
    { path: appPaths.signature, component: RequestSignatureComponent , title: 'Signature Request' },
    {
        canLoad: [MtCanLoadAuthGuard, IsProviderLoadGuard],
        path: 'provider-portal',
        loadChildren: () => import('./provider-portal/provider-portal.module').then((m) => m.ProviderPortalModule)
    },
    { canLoad: [MtCanLoadAuthGuard, IsAdminLoadGuard], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule) },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
