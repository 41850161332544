import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IAuditLogUser, IAuditLogUserService } from '@mt-ng2/audit-logging-module';

@Injectable({
	providedIn: 'root',
})
export class AuditLogUserService implements IAuditLogUserService {
	constructor(public http: HttpClient) {}

	getUsers(): Observable<IAuditLogUser[]> {
		return this.http.get<IAuditLogUser[]>('/users/get-all').pipe(
			map((users: IAuditLogUser[]) => {
				return users.map((user: IAuditLogUser) => {
					return {
						AuthUserId: user.AuthUserId,
						Name: user.Name,
					};
				});
			}),
		);
	}
}
